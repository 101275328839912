@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  font-family: 'Inter Grab Web', system-ui, -apple-system,
    /* Firefox supports this but not yet `system-ui` */ 'Segoe UI', Roboto, Helvetica, Arial,
    sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Grab Community';
  @apply text-default;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  @apply text-default;
}

/* Must do the reset at global level because Ant Design does not add custom className
 on the Menu component when it is attached to <body /> */

/* reset sub-menu background */
.ant-menu-sub.ant-menu-inline {
  background: unset;
}

.ant-menu {
  /* match with designer text color, ant-menu color is not using CSS Variable yet */
  @apply -mt-1 text-black;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  /* match with designer background and text color, ant-select is not using CSS Variable yet */
  @apply bg-very-light text-primary;
}

.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  @apply text-black bg-very-light;
}

svg {
  vertical-align: baseline;
}

/* This is a special handling for cookie banner because our svg display default is tailwind default 
which is display block. It will make the verified icon in cookie banner look bad.  */

/* https://jira.grab.com/browse/GW-4975 */
#ot-sdk-btn-floating .ot-floating-button__front svg {
  @apply inline-block;
}

#ot-sdk-btn-floating .ot-floating-button__back svg {
  @apply inline-block;
}
