/* Define all custom variables here */
:root {
  /* Tropical Green, branding color */
  --grab-green: #00b14f;
  --grab-dark-green: #005339;

  /* GDS Color / Green / 50 */
  --grab-green-50: #d9fcde;
  --grab-green-100: #eef9f9;
  --grab-green-150: #b3e8ca;
  --grab-green-200: #99bab0;
  --grab-green-300: #33c172;
  --grab-green-400: #00b14f;
  --grab-green-500: #277c74;
  --grab-green-600: #004720;
  --grab-green-800: #002e27;

  /* text & icon / link */
  --grab-blue: #136fd8;
  --grab-blue-light: #2596be;
  --grab-blue-light-2: #ccf2ff;
  --grab-blue-white: #ebfaff;
  --grab-gray-100: #bbc0c8;
  --grab-gray-600: #697078;
  --grab-gray-650: #575757;
  --grab-gray-700: #383e43;

  /* interactive element/negative */
  --grab-red: #f55538;
  --grab-red-100: #fff2f0;
  --grab-red-200: #ffe4e0;
  --grab-red-300: #ffd2cc;
  --grab-red-400: #ffb2a8;
  --grab-red-500: #d42e1c; 

  /* border-color-base */
  --grab-gray: #e5e9f0;

  /* GDS Color / Neutral Cool / 50 */
  --grab-gray-50: #f3f6fb;

  /* disabled background color */
  --grab-gray-20: #f5f5f5;

  /* disabled text color */
  --grab-gray-10: #bfbfbf;

  /* small label text color */
  --grab-gray-30: #707070;

  /* warning-color */
  --grab-orange: #f09800;

  /* dark-background */
  --grab-dark: #292929;

  /* black */
  --grab-black: #1a1a1a;

  /* white */
  --grab-white: #fff;
  --grab-color-text-primary: #242a2e;

  /* duxton color palette */

  /* black-subtle */
  --grab-black-subtle: #3d3d3d;

  /* status-netural-soft */
  --grab-status-netural-soft: #dbdbdb;

  /* status-alert-softest */
  --grab-status-alert-softest: #fdf2f2;

  /* status-warning-softest */
  --grab-status-warning-softest: #fff9c0;
}
