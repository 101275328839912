@font-face {
  font-family: 'Grab Community';
  src: url('./GrabCommunity/Grab\ Community\ Solid\ EN-Regular.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Grab Community';
  src: url('./GrabCommunity/Grab\ Community\ Solid\ EN-Bold.ttf') format('truetype');
  font-weight: 800;
  font-style: bold;
}
