.primaryButton,
.baseButton,
.baseButtonV2,
.primaryButtonV2 {
  @apply flex flex-row items-center gap-2 px-4 py-2 text-base rounded-full;

  svg {
    @apply text-sm;
  }
}

.baseButton {
  color: var(--grab-green-600);
  border-color: var(--grab-green-100);
  background-color: var(--grab-green-100);
  @apply text-base font-semibold;

  &:global(.ant-btn):active,
  &:global(.ant-btn):focus,
  &:global(.ant-btn):hover {
    color: var(--grab-green-600);
    border-color: var(--grab-green-150);
    background-color: var(--grab-green-150);
  }

  &:global(.ant-btn)[disabled],
  &:global(.ant-btn)[disabled]:active,
  &:global(.ant-btn)[disabled]:focus,
  &:global(.ant-btn)[disabled]:hover {
    color: var(--grab-green-600);
    border-color: var(--grab-green-200);
    background-color: var(--grab-green-200);
  }
}

.primaryButton {
  color: var(--grab-white);
  border-color: var(--grab-green);
  background-color: var(--grab-green);
  @apply text-base font-semibold;

  &:global(.ant-btn):active,
  &:global(.ant-btn):focus,
  &:global(.ant-btn):hover {
    color: var(--grab-white);
    border-color: var(--grab-green-300);
    background-color: var(--grab-green-300);
  }

  &:global(.ant-btn)[disabled],
  &:global(.ant-btn)[disabled]:active,
  &:global(.ant-btn)[disabled]:focus,
  &:global(.ant-btn)[disabled]:hover {
    color: var(--grab-white);
    border-color: var(--grab-green-200);
    background-color: var(--grab-green-200);
  }
}

.baseButtonV2 {
  color: var(--grab-green-600);
  border-color: var(--grab-green-600);
  background-color: var(--grab-white);
  @apply text-base font-semibold antialiased;

  &:global(.ant-btn):active,
  &:global(.ant-btn):focus,
  &:global(.ant-btn):hover {
    color: var(--grab-white);
    border-color: var(--grab-green);
    background-color: var(--grab-green);
  }

  &:global(.ant-btn)[disabled],
  &:global(.ant-btn)[disabled]:active,
  &:global(.ant-btn)[disabled]:focus,
  &:global(.ant-btn)[disabled]:hover {
    color: var(--grab-white);
    border-color: var(--grab-green-200);
    background-color: var(--grab-green-200);
  }
}

.primaryButtonV2 {
  color: var(--grab-white);
  border-color: var(--grab-green);
  background-color: var(--grab-green);
  @apply text-base font-semibold antialiased py-2.5;

  &:global(.ant-btn):active,
  &:global(.ant-btn):focus,
  &:global(.ant-btn):hover {
    color: var(--grab-white);
    border-color: var(--grab-green-300);
    background-color: var(--grab-green-300);
  }

  &:global(.ant-btn)[disabled],
  &:global(.ant-btn)[disabled]:active,
  &:global(.ant-btn)[disabled]:focus,
  &:global(.ant-btn)[disabled]:hover {
    color: var(--grab-white);
    border-color: var(--grab-green-200);
    background-color: var(--grab-green-200);
  }
}
