/* Define Ant Design overridden variables here by reusing tokens defined in /grab.vars.css */
:root {
  /*
    Please mention why you are overriding a variable here instead of css module level.
    Useful to track which component is not designed properly
    so that we might re-implement/find alternative later
  */

  /* Used by:
      - <Menu />, at global scope, due to mounting component at <body />
      - <add more here>
  */
  --ant-primary-color: var(--grab-green);

  /* Used by:
      - <Menu />, at global scope, due to mounting component at <body />
      - <add more here>
  */
  --ant-primary-1: var(--grab-gray-50);

  /* Used by:
      - <MenuItem danger />
      - <add more here>
  */
  --ant-error-color: var(--grab-red);
}
