@font-face {
  font-family: 'Inter Grab Web';
  src: url('./InterLooped/InterLooped-Light.woff2') format('woff2'),
    url('./InterLooped/InterLooped-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Inter Grab Web';
  src: url('./InterLooped/InterLooped-Italic.woff2') format('woff2'),
    url('./InterLooped/InterLooped-Italic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Inter Grab Web';
  src: url('./InterLooped/InterLooped-Regular.woff2') format('woff2'),
    url('./InterLooped/InterLooped-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Inter Grab Web';
  src: url('./InterLooped/InterLooped-MediumItalic.woff2') format('woff2'),
    url('./InterLooped/InterLooped-MediumItalic.woff') format('woff');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Inter Grab Web';
  src: url('./InterLooped/InterLooped-Medium.woff2') format('woff2'),
    url('./InterLooped/InterLooped-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Inter Grab Web';
  src: url('./InterLooped/InterLooped-MediumItalic.woff2') format('woff2'),
    url('./InterLooped/InterLooped-Medium.woff') format('woff');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Inter Grab Web';
  src: url('./InterLooped/InterLooped-Bold.woff2') format('woff2'),
    url('./InterLooped/InterLooped-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Inter Grab Web';
  src: url('./InterLooped/InterLooped-BoldItalic.woff2') format('woff2'),
    url('./InterLooped/InterLooped-BoldItalic.woff') format('woff');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Inter Grab Web';
  src: url('./InterLooped/InterLooped-ExtraBold.woff2') format('woff2'),
    url('./InterLooped/InterLooped-ExtraBold.woff') format('woff');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Inter Grab Web';
  src: url('./InterLooped/InterLooped-ExtraBoldItalic.woff2') format('woff2'),
    url('./InterLooped/InterLooped-ExtraBoldItalic.woff') format('woff');
  font-weight: 800;
  font-style: italic;
}
